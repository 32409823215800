import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Button,
    Container,
} from 'react-bootstrap';
import Header from '@Components/Header';
import PaymentForm from '@Components/PaymentForm';

//import PayPal from '@Components/PayPal';
import EbanxDirectPay from '@Components/EbanxDirectPay';

import {
    useParams,
    useSearchParams,
    useNavigate
} from "react-router-dom";
import { IoChevronBack, IoCard } from "react-icons/io5";

import { useDispatch, useSelector } from 'react-redux';

import utils from '@Components/utils';
import FetchingComponent from '@Components/FetchingComponent';

import { useTranslation } from 'react-i18next';

function PaymentScreen() {
    const { t } = useTranslation();
    const [paymentMethod, setPaymentMethod] = React.useState('card');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = useParams().token;
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

    React.useEffect(() => {

        // load analytics that user is in payment screen

        dispatch({
            type: 'CART_OPERATION',
            payload: {
                token,
                method: 'GET',
                analytics: 'payment_sc',
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        cart,
        cart_items,
        fetching,
        api_result
    } = dataReducer;


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI :: Pago</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <FetchingComponent
                fetching={fetching}
                api_result={api_result}
                account={account}
                whatsappMessage="Pago de pedido"
                custom_message="expired_token_payment"
            >
                <Container
                    className='mt-3 mb-7'
                    style={{
                        minWidth: 350,
                        maxWidth: 400,
                    }}
                >

                    <div className='
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                        mb-2
                    '>
                        <hr />
                        <h5>Total {utils.formatCurrency(cart?.all_in_price_total, cart?.currency)}</h5>

                    </div>


                    <div

                    >
                        <div
                            className='mb-2 d-flex flex-column justify-content-center align-items-center'
                        >


                            <Button
                                onClick={() => { setPaymentMethod(paymentMethod === 'card' ? '' : 'card') }}
                                variant={paymentMethod === 'card' ? 'primary' : 'outline-primary'}
                                className='mb-1'
                                style={{ width: 140 }}
                            >
                                <IoCard /> Tarjeta
                            </Button>

                            <EbanxDirectPay
                                setPaymentMethod={setPaymentMethod}
                                paymentMethod={paymentMethod}
                                token={token}

                            />


                        </div>
                        {
                            paymentMethod === 'card' &&
                            <PaymentForm
                                price={cart?.cart_total}
                                submitPayment={(data) => {
                                    dispatch({
                                        type: 'CART_PAY',
                                        fetching: 'cart_paying',
                                        fetching_done: 'cart_paid',
                                        cart_operation_pay: true,
                                        payload: {
                                            cart_id: cart?.shopping_cart_id,
                                            token,
                                            method: 'POST',
                                            body: {
                                                payment: data
                                            }
                                        }
                                    })

                                }}
                            />
                        }

                    </div>





                    { /*
                        <div
                            className='mt-1'
                        >
                            o realizar pago con PayPal
                            <br />
                            <PayPal
                                acceptCreditCards={false}
                            />
                        </div>
                    */}
                    <hr />

                    {fetching === 'cart_fetching' ?

                        <div
                            className='d-flex justify-content-center align-items-center'
                        >

                            <div className="spinner-border" role="status" style={{ width: 20, height: 20 }}>

                            </div>
                            &nbsp;
                            &nbsp;
                            Loading...

                        </div>

                        :
                        <>


                            <div>
                                <Button
                                    size='sm'
                                    variant="secondary"
                                    className='mb-2'
                                    onClick={() => {
                                        navigate(`/${token}/shipping`);
                                    }}
                                >
                                    <IoChevronBack />
                                    {t('Dirección')}
                                </Button>
                            </div>

                            <div>
                                {account?.first_name} {account?.last_name}
                            </div>
                            <div>
                                {account?.email}
                            </div>
                            <div>
                                {account?.phone} &nbsp;

                            </div>
                            <div>
                                {account?.address}
                            </div>
                            <div>
                                {account?.address2}
                            </div>
                            <div>
                                {account?.city} {account?.state} {account?.zip || account?.zipcode} {account?.country}
                            </div>

                            <hr />

                            <div>
                                <Button
                                    size='sm'

                                    className='mb-2'
                                    variant="secondary"
                                    onClick={() => {

                                        navigate(`/${token}/cart/`);
                                    }}
                                >
                                    <IoChevronBack />
                                    {t('Carrito de Compras')}
                                </Button>
                                {
                                    cart_items?.map((item, index) => {

                                        return <div
                                            key={index}
                                            className='mb-2'
                                        >
                                            <div
                                            >   {item.quantity} x {item.data.name}
                                            </div>
                                            <div>
                                                <b>{utils.formatCurrency(item.quantity * item.data.allInPrice, cart?.currency)}</b>
                                            </div>
                                        </div>

                                    })
                                }
                                <div>
                                    <hr />
                                    <h5>Total {utils.formatCurrency(cart?.all_in_price_total, cart?.currency)}</h5>

                                </div>

                            </div>




                        </>
                    }


                </Container>
            </FetchingComponent>
            <br /><br />
        </>
    );
}

export default PaymentScreen;
