import React, { useState } from 'react';

import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import { Container, Button, } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import api from '@Services/api';

import Footer from '@Components/Footer';
import { Form } from 'react-bootstrap';

import { IoClose, IoSearch } from "react-icons/io5";


import { useLocation, } from 'react-router-dom';

import utils from '@Components/utils';

const ProductList = ({ products, token }) => {
    const { t } = useTranslation(); //eslint-disable-line no-unused-vars
    const [cookies] = useCookies(['alani360']);
    //products is an array of products
    if (!products) return null;

    return <div className="mt-0">
        {products.map((oferta, index) => (
            <div key={index} className="card mb-3">
                <div className="row g-0">
                    <div>
                        <div className="card-body">
                            <img src={oferta.image} className="p-2 img-fluid rounded-start" style={{ maxWidth: 100, float: 'left', marginRight: '15px' }} alt={oferta.name} />

                            <h5 className="card-title">{oferta.name}</h5>

                            <div className="card-text description-container">
                                <p
                                    className="description"
                                    style={{
                                        maxHeight: '100px',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        marginBottom: 0,
                                        transition: 'max-height 0.3s ease-out'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: oferta.longDescription }}
                                />

                                <span
                                    style={{
                                        fontSize: 14,
                                        cursor: 'pointer'
                                    }}
                                    className="btn btn-link"
                                    onClick={(e) => {
                                        const desc = e.target.previousElementSibling;
                                        if (desc.style.maxHeight === '100px') {
                                            desc.style.maxHeight = '2000px';
                                            e.target.textContent = t('Ver menos');
                                        } else {
                                            desc.style.maxHeight = '100px';
                                            e.target.textContent = t('Ver más');
                                        }
                                    }}
                                >
                                    Ver más
                                </span>
                            </div>

                            <div className="d-flex justify-content-between align-items-end mt-2">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    {oferta.allInPriceRegular &&


                                        (oferta.allInPriceRegular - oferta.allInPrice > 40)

                                        &&
                                        (
                                            <span style={{
                                                textDecoration: 'line-through',
                                                marginBottom: '5px'
                                            }}>
                                                {utils.formatCurrency(oferta.allInPriceRegular, oferta.currency_code)}
                                            </span>
                                        )}

                                    <Button
                                        style={{
                                            fontSize: 15,
                                            textAlign: 'center',
                                            padding: '5px 20px',
                                            borderRadius: 5,

                                            color: 'white',
                                            border: 'none',
                                        }}
                                        onClick={() => {

                                            window.open(`/b/${token}/${oferta.sku}?r=1`, '_blank');

                                        }}
                                    >
                                        {utils.formatCurrency(oferta.allInPrice, oferta.currency_code)}
                                    </Button>

                                    <div style={{ fontWeight: 600, marginTop: 10, fontSize: 14, textAlign: 'center', width: '100%' }}>
                                        {t('Incluye costos de importación y envío')}
                                        <br />
                                        a {utils.countryName(cookies.alani360)}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>

}



function SearchWapResults() {

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const { t } = useTranslation(); //eslint-disable-line no-unused-vars
    let token = useParams().token;

    const [loading, setLoading] = useState(true);


    const location = useLocation();

    const [results, setResults] = useState();

    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(false);
    const [originalSearchTerm, setOriginalSearchTerm] = useState(''); //eslint-disable-line no-unused-vars


    const handleSearch = (search) => {
        const search_code = new URLSearchParams(location.search).get('s');
        search = search.trim();
        setSearching(true);
        setResults([]);
        api.search_results_wap({ search_code, search })
            .then(data => {
                setResults(data);
                setSearching(false);

            })

    }
    //search_code from query params


    React.useEffect(() => {
        const search_code = new URLSearchParams(location.search).get('s');
        api.search_results_wap({ search_code })
            .then(data => {
                setLoading(false);
                setSearchTerm(data.search_query);
                setOriginalSearchTerm(data.search_query);
                setResults(data);
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Resultados</title>
            </Helmet>
        </HelmetProvider>





        {
            loading ?

                <div
                    className='d-flex flex-column justify-content-center align-items-center mt-5'
                >
                    <div className="d-flex justify-content-center my-5">

                        <div className="spinner-border text-primary" role="status"></div>
                    </div>

                </div>
                :

                <Container className="p-3 mb-3 " style={{ maxWidth: 550, textAlign: "center" }}>

                    <h5>{t('Resultados')}</h5>

                    <Container className="p-1 mb-2" style={{ maxWidth: 350 }}>

                        <Form.Group className="d-flex flex-row justify-content-between align-items-center p-0 m-0">
                            <div className="position-relative" style={{ flex: 1 }}>
                                <Form.Control
                                    width="200"
                                    type="text"
                                    placeholder="Buscar"
                                    disabled={loading}
                                    style={{
                                        backgroundColor: searchTerm ? 'lightyellow' : '#fff',
                                        border: searchTerm ? '2px solid #007bff' : '1px solid #ced4da',
                                        paddingRight: 25,
                                        fontSize: 16
                                    }}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSearch(searchTerm);
                                        }
                                    }}
                                />
                                {searchTerm && (
                                    <Button
                                        variant="link"
                                        className="position-absolute"
                                        style={{
                                            right: 10,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            padding: 0,
                                            color: '#6c757d'
                                        }}
                                        onClick={() => {
                                            setSearchTerm("");
                                            //handleSearch(originalSearchTerm);
                                        }}
                                    >
                                        <IoClose />
                                    </Button>
                                )}
                            </div>
                            <Button
                                variant={searching ? "secondary" : "dark"}
                                className='ms-2'
                                size='md'
                                disabled={searching}
                                onClick={() => handleSearch(searchTerm)}
                                style={{
                                    width: 60
                                }}
                            >

                                {searching ?
                                    <div className="spinner-border spinner-border-sm text-light ms-2" role="status" style={{ width: '1rem', height: '1rem' }}></div>
                                    :
                                    <IoSearch />
                                }
                            </Button>
                        </Form.Group>




                    </Container>

                    <ProductList products={results?.products} token={token} />

                </Container>

        }



        {
            !loading && results?.products?.length > 0 &&
            <Footer />
        }




    </>


}

export default SearchWapResults;
