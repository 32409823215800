import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import utils from '@Components/utils';
import { useCookies } from 'react-cookie';
import { IoLogoWhatsapp, IoMail } from "react-icons/io5";

const Footer = () => {
    const { t } = useTranslation();
    const token = useParams().token;
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const navigate = useNavigate();
    const [marginTop, setMarginTop] = useState(0);

    useEffect(() => {
        const updateMargin = () => {
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.offsetHeight;
            const footerHeight = document.querySelector('footer').offsetHeight;

            if (bodyHeight < windowHeight) {
                setMarginTop(windowHeight - bodyHeight - footerHeight);
            } else {
                setMarginTop(0);
            }
        };

        updateMargin();
        window.addEventListener('resize', updateMargin);

        return () => window.removeEventListener('resize', updateMargin);
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <footer className="bg-light py-4 mt-4" style={{ marginTop: `${marginTop}px` }}>
            <Container>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <h5>{t('Links')}</h5>
                        {
                            !!token ?
                                <ul className="list-unstyled">
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/home/${token}/`)}>{t('Inicio')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/ayuda`)}>{t('Ayuda')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/consideraciones`)}>{t('Consideraciones')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/terms`)}>Términos y Condiciones</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/marcas`)}>{t('Catálogo')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/privacy`)}>{t('Privacidad')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation(`/${token}/bby`)}>Best Buy</button></li>
                                </ul>

                                :
                                //repeat same links without token
                                //turn every link into a button
                                <ul className="list-unstyled">
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/home")}>{t('Inicio')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/ayuda")}>{t('Ayuda')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/consideraciones")}>{t('Consideraciones')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/terms")}>T&C</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/privacy")}>{t('Privacidad')}</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/bby")}>Best Buy</button></li>
                                    <li><button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='link-secondary' onClick={() => handleNavigation("/country")}>País</button></li>
                                </ul>
                        }




                    </Col>
                    <Col md={4}>
                        <h5>{t("home.contactanos")}</h5>
                        <a
                            target="_blank" rel="noreferrer" href={`mailto:info@alani360.com`} className="div-row"
                        ><IoMail /> info@alani360.com</a>
                        <br />

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}}`}
                        >
                            <img
                                style={{
                                    width: 23,
                                    marginRight: 10

                                }}
                                alt={cookies.alani360}

                                src={`/images/flags/${cookies.alani360}.png`}
                            />
                            <IoLogoWhatsapp />{utils.getWhatsappNumber(cookies.alani360)}
                        </a>

                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <p>&copy; {new Date().getFullYear()} ALANI360 {t('All rights reserved')}.</p>
                    </Col>
                </Row>
            </Container>
        </footer >
    );
};

export default Footer;
