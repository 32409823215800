import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import FeaturedProduct from '@Components/FeaturedProduct';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const HEADPHONE_CATEGORIES = [
    {
        id: 'hp-earbuds',
        label: 'Earbuds',
        subcategories: []
    },
    {
        id: 'hp-over-ear',
        label: 'Over the Ear',
        subcategories: []
    },
    {
        id: 'hp-noise-cancelling',
        label: 'Noise Cancelling',
        subcategories: []
    },
    {
        id: 'hp-gaming',
        label: 'Gaming',
        subcategories: []
    },
    {
        id: 'hp-sports',
        label: 'Sports',
        subcategories: []
    },
    {
        id: 'hp-kids',
        label: 'Kids',
        subcategories: []
    }
];

export const HeadPhonesLandingPage = ({
    token,
    handleSearch
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas Audífonos</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <Button
                variant='outline-dark'
                className='mb-3'
                size='sm'
                onClick={() => navigate(!!token ? `/${token}/ofertas/` : '/ofertas/')}
            >
                <IoChevronBack /> Ofertas
            </Button>

            <h4>🎧 {t('Ofertas Audífonos')}</h4>

            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>
                    <p>¡Ofertas de Audífonos!</p>
                </center>
            </div>

            <HeadPhonesCategoriesButtons
                offer_code={""}
                token={token}
                handleSearch={handleSearch}
            />
            <FeaturedProduct
                name="<h5>Sony WH-1000XM5</h5>"
                description="Además de estos productos en oferta o con descuento, tenemos miles de productos más y exclusivos de <b>Best&nbsp;Buy powered by ALANI 360</b>:"
                imageUrl="https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6505/6505727_sd.jpg"
                imageStyle={{
                    width: 320,
                }}
                url={!!token ? `/${token}/sku/6505727` : `/_/sku/6505727`}
            />
        </Container>
    </>
}

export const HeadPhonesCategoriesButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const handleClick = (path, searchParams) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${path}`);
        handleSearch({
            change_page: true,
            loading_searching: searchParams.loading_searching,
            p_offer_code: searchParams.p_offer_code
        });
    };

    const isActiveCategory = (categoryId) => {
        const category = HEADPHONE_CATEGORIES.find(cat => cat.id === categoryId);
        return category && (
            offer_code === category.id ||
            category.subcategories.some(sub => sub.id === offer_code)
        );
    };

    return <div className='d-flex flex-wrap justify-content-center align-items-center mb-2'>
        {HEADPHONE_CATEGORIES.map(category => (
            <Button
                key={category.id}
                className='me-2 mb-2'
                variant={isActiveCategory(category.id) ? "success" : "outline-success"}
                size='sm'
                onClick={() => handleClick(category.id, {
                    loading_searching: "searching",
                    p_offer_code: category.id
                })}
                disabled={disabled}
            >
                {category.label}
            </Button>
        ))}
    </div>
} 