import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import FeaturedProduct from '@Components/FeaturedProduct';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const GAMING_CATEGORIES = [
    {
        id: 'games',
        label: 'Juegos',
        subcategories: [
            {
                id: 'xbox-juegos',
                label: 'Xbox',
                variant: 'dark'
            },
            {
                id: 'ps5-juegos',
                label: 'PS5',
                variant: 'dark'
            },
            {
                id: 'ps4-juegos',
                label: 'PS4',
                variant: 'dark'
            },
            {
                id: 'nintendo-juegos',
                label: 'Nintendo',
                variant: 'dark'
            }
        ]
    },
    {
        id: 'pc-gaming',
        label: 'PC Gaming',
        subcategories: [
            {
                id: 'pc-gaming-desktop',
                label: 'Desktop',
                variant: 'dark'
            },
            {
                id: 'pc-gaming-laptop',
                label: 'Laptop',
                variant: 'dark'
            },
            {
                id: 'pc-gaming-display',
                label: 'Monitores',
                variant: 'dark'
            },
            {
                id: 'pc-gaming-accesorios',
                label: 'Accesorios',
                variant: 'dark'
            }
        ]
    },
    {
        id: 'more-gaming',
        label: 'Más Gaming',
        subcategories: [
            {
                id: 'consoles-gaming',
                label: 'Cónsolas',
                variant: 'dark'
            },
            {
                id: 'vr-gaming',
                label: 'VR',
                variant: 'dark'
            },
            {
                id: 'accesorios-gaming',
                label: 'Accesorios',
                variant: 'dark'
            },
            {
                id: 'handhelds-gaming',
                label: 'Handhelds',
                variant: 'dark'
            }
        ]
    }
];

export const GamingLandingPage = ({
    token,
    handleSearch
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas Gaming</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <Button

                variant='outline-dark'
                className='mb-3'
                size='sm'
                onClick={() => navigate(!!token ? `/${token}/ofertas/` : '/ofertas/')}
            >
                <IoChevronBack /> Ofertas
            </Button>

            <h4>🎮 {t('Ofertas Gaming')}</h4>

            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>

                    {t('Ofertas de Productos Gaming')}


                </center>
            </div>

            <GamingCategoriesButtons
                offer_code={""}
                token={token}
                handleSearch={handleSearch}
            />
            <FeaturedProduct
                name="<h5>Backbone One (USB-C) x Call of Duty</h5><small><i>No incluye el iPhone</i></small>"
                description="Además de estos productos en oferta o con descuento, tenemos miles de productos más y exclusivos de <b>Best&nbsp;Buy powered by ALANI 360</b>:"
                imageUrl="https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6571/6571317_sd.jpg"
                imageStyle={{
                    width: 320,
                }}
                url={!!token ? `/${token}/sku/6571317` : `/_/sku/6571317`}
            />
        </Container>
    </>
}

export const GamingCategoriesButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const handleClick = (path, searchParams) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${path}`);
        handleSearch({
            change_page: true,
            loading_searching: searchParams.loading_searching,
            p_offer_code: searchParams.p_offer_code
        });
    };

    const isActiveCategory = (categoryId) => {
        const category = GAMING_CATEGORIES.find(cat => cat.id === categoryId);
        return category && (
            offer_code === category.id ||
            category.subcategories.some(sub => sub.id === offer_code)
        );
    };

    return <div className='d-flex flex-column justify-content-around align-items-center mb-2'>
        <div className='d-flex flex-row justify-content-around align-items-center'>
            {GAMING_CATEGORIES.map(category => (
                <Button
                    key={category.id}
                    className='me-2'
                    variant={isActiveCategory(category.id) ? "success" : "outline-success"}
                    size='sm'
                    onClick={() => handleClick(category.id, {
                        loading_searching: "searching",
                        p_offer_code: category.id
                    })}
                    disabled={disabled}
                >
                    {category.label}
                </Button>
            ))}
        </div>
    </div>
}

export const GamesLandingPage = ({
    token,
    handleSearch
}) => {
    const { t } = useTranslation();

    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas Gaming</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <h4>🎮 {t('Ofertas Games')}</h4>


            <GamingCategoriesButtons
                offer_code={"games"}
                token={token}
                handleSearch={handleSearch}
            />


            <GamesSubcategoryButtons
                offer_code={""}
                token={token}
                handleSearch={handleSearch}
            />


            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>

                    LANDING PAGE OFERTAS GAMES
                </center>
            </div>
        </Container>
    </>
}

export const GamesSubcategoryButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const button_variant = "dark";
    const [offer_code_state, setOfferCodeState] = useState(offer_code);

    const handleButtonClick = (platformCode) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${platformCode}`);
        setOfferCodeState(platformCode);
        handleSearch({
            loading_searching: "searching",
            p_offer_code: platformCode
        });
    };

    const gamesCategory = GAMING_CATEGORIES.find(cat => cat.id === 'games');

    return <div
        className='d-flex flex-column justify-content-around align-items-center mb-2'
    >
        <div className='d-flex flex-row justify-content-around align-items-center'>
            {gamesCategory.subcategories.map(subcategory => (
                <Button
                    key={subcategory.id}
                    className='me-2'
                    variant={(offer_code_state === subcategory.id) ? button_variant : "outline-" + button_variant}
                    size='sm'
                    onClick={() => handleButtonClick(subcategory.id)}
                    disabled={disabled}
                >
                    {subcategory.label}
                </Button>
            ))}
        </div>
    </div>
}

export const PCSubcategoryButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const button_variant = "dark";
    const [offer_code_state, setOfferCodeState] = useState(offer_code);

    const handleButtonClick = (platformCode) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${platformCode}`);
        setOfferCodeState(platformCode);
        handleSearch({
            loading_searching: "searching",
            p_offer_code: platformCode
        });
    };

    return <div
        className='d-flex flex-column justify-content-around align-items-center mb-2'
    >
        <div className='d-flex flex-row justify-content-around align-items-center'>
            <Button
                className='me-2'
                variant={(offer_code_state === 'pc-gaming-desktop') ? button_variant : "outline-" + button_variant}
                size='sm'
                onClick={() => handleButtonClick('pc-gaming-desktop')}
                disabled={disabled}
            >
                Desktop
            </Button>

            <Button
                className='me-2'
                variant={(offer_code_state === 'pc-gaming-laptop') ? button_variant : "outline-" + button_variant}
                size='sm'
                onClick={() => handleButtonClick('pc-gaming-laptop')}
                disabled={disabled}
            >
                Laptop
            </Button>

            <Button
                className='me-2'
                variant={(offer_code_state === 'pc-gaming-display') ? button_variant : "outline-" + button_variant}
                size='sm'
                onClick={() => handleButtonClick('pc-gaming-display')}
                disabled={disabled}
            >
                Monitores
            </Button>
            <Button
                className='me-2'
                variant={(offer_code_state === 'pc-gaming-accesorios') ? button_variant : "outline-" + button_variant}
                size='sm'
                onClick={() => handleButtonClick('pc-gaming-accesorios')}
                disabled={disabled}
            >
                Accesorios
            </Button>

        </div>
    </div>
}

export const MoreSubcategoryButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const button_variant = "dark";
    const [offer_code_state, setOfferCodeState] = useState(offer_code);

    const handleButtonClick = (platformCode) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${platformCode}`);
        setOfferCodeState(platformCode);
        handleSearch({
            loading_searching: "searching",
            p_offer_code: platformCode
        });
    };

    const moreGamingCategory = GAMING_CATEGORIES.find(cat => cat.id === 'more-gaming');

    return <div
        className='d-flex flex-column justify-content-around align-items-center mb-2'
    >
        <div className='d-flex flex-row justify-content-around align-items-center'>
            {moreGamingCategory.subcategories.map(subcategory => (
                <Button
                    key={subcategory.id}
                    className='me-2'
                    variant={(offer_code_state === subcategory.id) ? button_variant : "outline-" + button_variant}
                    size='sm'
                    onClick={() => handleButtonClick(subcategory.id)}
                    disabled={disabled}
                >
                    {subcategory.label}
                </Button>
            ))}
        </div>
    </div>
}