import React from 'react';

import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
//import FeaturedProduct from '@Components/FeaturedProduct';
import { useParams } from 'react-router-dom';

import { useTranslation, } from 'react-i18next';

const OfertasLandingPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();



    return (<>

        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas</title>
            </Helmet>
        </HelmetProvider>

        <div
            className='d-flex flex-column justify-content-center align-items-center'

        >


            <div
                className='d-flex flex-row justify-content-center align-items-center mb-3 mt-3'

            >
                <h2 className='m-0 me-2'>Ofertas</h2>
                <img
                    style={{
                        height: 40,
                    }}
                    src="/images/bby_logo.png"
                    alt="Powered by Alani360"
                />
                <img
                    style={{
                        width: 55,
                        marginBottom: -30,
                    }}
                    src="/images/alani360_1.png"
                    alt="Powered by Alani360"
                />
            </div>

            <div

                className='d-flex flex-row justify-content-around align-items-around'
                style={{
                    width: '100%',
                    maxWidth: 370
                }}
            >
                <Button
                    style={{
                        width: 190

                    }}
                    variant="primary"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/ofertas-del-dia` : '/ofertas/ofertas-del-dia')}
                >
                    {t('ofertas.deals_of_the_day')}&nbsp;⚡️

                </Button>



            </div>

            <div

                className='mt-2 d-flex flex-row justify-content-around align-items-center'
                style={{
                    width: '100%',
                    maxWidth: 370
                }}
            >
                <Button
                    style={{
                        width: 170,
                        margin: 0
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/gaming` : '/ofertas/gaming')}
                >
                    {t('Gaming')}&nbsp;🕹️

                </Button>
                <Button
                    style={{
                        width: 170
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/headphones` : '/ofertas/headphones')}
                >
                    {t('Audífonos')}&nbsp;🎧

                </Button>




            </div>

            <div


                className='mt-2 d-flex flex-row justify-content-around align-items-center'
                style={{
                    width: '100%',
                    maxWidth: 370
                }}
            >
                <Button
                    style={{
                        width: 170,
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/laptops` : '/ofertas/laptops')}
                >
                    {t('Laptops')}&nbsp;💻
                </Button>

                <Button
                    style={{
                        width: 170

                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/speakers` : '/ofertas/speakers')}
                >
                    {t('Speakers')}&nbsp;🎵

                </Button>



            </div>

            <div

                className='mt-2 d-flex flex-row justify-content-around align-items-center'
                style={{
                    width: '100%',
                    maxWidth: 370
                }}
            >

                <Button
                    style={{
                        width: 170,
                        margin: 0
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/dyson` : '/ofertas/dyson')}
                >
                    Dyson
                </Button>
                <Button
                    style={{
                        width: 170,
                        margin: 0
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/lego` : '/ofertas/lego')}
                >
                    Lego
                </Button>



            </div>

            <div

                className='mt-2 d-flex flex-row justify-content-around align-items-center'
                style={{
                    width: '100%',
                    maxWidth: 370
                }}
            >

                <Button
                    style={{
                        width: 170,
                        margin: 0
                    }}
                    variant="success"
                    size='md'
                    onClick={() => navigate(!!token ? `/${token}/ofertas/reacondicionados` : '/ofertas/reacondicionados')}
                >
                    {t('Reacondicionados')}
                </Button>
                <div style={{ width: 170 }}></div>


            </div>

            <Button
                className='mt-2'
                size='md'
                onClick={() => navigate(!!token ? `/${token}/buscar` : '/buscar')}
                style={{
                    width: 190
                }}
            >

                {t('Otros Productos')}&nbsp;🛍️

            </Button>

        </div >
    </>
    );
};

export default OfertasLandingPage;
