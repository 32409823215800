import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';

const APPLE_CATEGORIES = [
    {
        id: 'apple-refub',
        label: 'MacBook',
        subcategories: []
    },
    {
        id: 'ipad-refub',
        label: 'iPad',
        subcategories: []
    },
    {
        id: 'iphone-refub',
        label: 'iPhone',
        subcategories: []
    },
    {
        id: 'iwatch-refub',
        label: 'iWatch',
        subcategories: []
    },
    {
        id: 'beats-refub',
        label: 'Beats',
        subcategories: []
    },
    {
        id: 'earphones-refub',
        label: 'AirPods',
        subcategories: []
    }
];

export const AppleLandingPage = ({
    token,
    handleSearch
}) => {
    const { t } = useTranslation();
    const [cookies] = useCookies(['alani360']);

    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <h4>🤑 {t('Ofertas')}</h4>

            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>
                    <p>¡Ofertas de Productos Apple Reacondicionados!</p>
                    {cookies.alani360 === 'MX' &&
                        <p>
                            Compra hasta en 12 cuotas sin interés
                            <br />
                            con tu tarjeta.
                        </p>
                    }
                    <p>
                        Descubre increíbles descuentos de productos Apple. Los productos cambian frecuentemente, ¡no te los pierdas! Para ver estas ofertas, escríbenos "Ofertas Apple".
                    </p>
                </center>
            </div>

            <AppleCategoriesButtons
                offer_code={""}
                token={token}
                handleSearch={handleSearch}
            />
        </Container>
    </>
}

export const AppleCategoriesButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const handleClick = (path, searchParams) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${path}`);
        handleSearch({
            change_page: true,
            loading_searching: searchParams.loading_searching,
            p_offer_code: searchParams.p_offer_code
        });
    };

    const isActiveCategory = (categoryId) => {
        const category = APPLE_CATEGORIES.find(cat => cat.id === categoryId);
        return category && (
            offer_code === category.id ||
            category.subcategories.some(sub => sub.id === offer_code)
        );
    };

    return <div className='d-flex flex-wrap justify-content-center align-items-center mb-2'
        style={{
            maxWidth: 300,
        }}
    >
        {APPLE_CATEGORIES.map(category => (
            <Button
                key={category.id}
                className='me-2 mb-2'
                variant={isActiveCategory(category.id) ? "success" : "outline-success"}
                size='sm'
                onClick={() => handleClick(category.id, {
                    loading_searching: "searching",
                    p_offer_code: category.id
                })}
                disabled={disabled}
            >
                {category.label}
            </Button>
        ))}
    </div>
} 