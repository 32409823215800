import React from 'react';
import {
    useState
} from 'react';
import Header from '@Components/Header';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container, Spinner,
} from 'react-bootstrap';
import { useParams, Link } from "react-router-dom";

import api from '@Services/api';




function OrderScreen() {

    const token = useParams().token;
    const search_code = useParams().search_code;
    const [fetching, setFetching] = useState(false);
    const [results, setResults] = useState([]);




    React.useEffect(() => {
        setFetching(true);
        api.fetch({
            method: 'get',
            path: `account/${token}/searches/${search_code}`
        }).then(res => {
            setResults(res);
            setFetching(false);

        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 Búsqueda</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <Container
            className='container-main mt-3'
        >
            <h3>Búsqueda</h3>

            <p>
                Estos otros productos pueden interesarte
            </p>
            {
                fetching ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner animation="border" />
                    </div >

                    :
                    <>
                        {results?.search_term?.replaceAll('+', ' ')}
                        <ul
                            className='mt-3'
                        >
                            {results?.search_results?.map((result, index) => {

                                return <li
                                    key={index}
                                >
                                    <Link
                                        to={`/${token}/sku/${result.sku}`}
                                    >
                                        {result.product_name}
                                    </Link>
                                </li>

                            })}
                        </ul>

                    </>

            }
        </Container>
    </>
}

export default OrderScreen;
