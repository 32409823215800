import React, { useState, useEffect } from 'react';

import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import api from '@Services/api';

import ExpiredToken from '@Components/ExpiredToken';
import Footer from '@Components/Footer';
import { IoClose, IoSearch } from "react-icons/io5";

import { useDispatch, useSelector } from 'react-redux';


import { useLocation, useNavigate } from 'react-router-dom';
import OfertasList from '@Components/OfertasList';
import { AppleLandingPage, AppleCategoriesButtons } from '@Components/AppleOfertas';
import {
    GamingLandingPage,
    GamingCategoriesButtons,
    GamesSubcategoryButtons,
    MoreSubcategoryButtons,
    PCSubcategoryButtons
} from '@Components/GamingOfertas';

import { HeadPhonesCategoriesButtons } from '@Components/HeadPhonesOfertas';
import { LaptopCategoriesButtons } from '@Components/LaptopOfertas';
import { SpeakerCategoriesButtons } from '@Components/OfertasSpeakers';

import { IoChevronBack } from "react-icons/io5";
import { t, Trans } from 'react-i18next';
const CountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const getTimeRemaining = () => {
            const now = new Date();
            const etMidnight = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
            etMidnight.setHours(24, 0, 0, 0);
            const timeDiff = etMidnight.getTime() - now.getTime();

            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            return { hours, minutes, seconds };
        };

        const updateCountdown = () => {
            const timer = getTimeRemaining();
            setTimeRemaining(timer);
        };

        const timerInterval = setInterval(updateCountdown, 1000);

        // Initial call to display the countdown immediately
        updateCountdown();

        // Clean up the interval on component unmount
        return () => clearInterval(timerInterval);
    }, []);

    const formatTime = (time) => time.toString().padStart(2, '0');

    return (
        <div
            className="p-1 pb-0"
        >
            <div style={{
                fontSize: 17,
                width: 125, backgroundColor: "#e0e0e0", padding: '5px 10px', borderRadius: 5
            }}>
                🕓
                &nbsp;
                {timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? (
                    "Day ended!"
                ) : (
                    `${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`
                )}
            </div>
        </div >
    );
};

const OfertasCategories = ({
    handleSearch
}) => {
    let token = useParams().token;
    let offer_code = useParams().offer_code;
    const handleButtonClick = (p_offer_code) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${p_offer_code}`);
        //setOfferCode(p_offer_code);

        handleSearch({
            loading_searching: "searching",
            p_offer_code: p_offer_code
        });
    };
    return <>
        <div className='d-flex 
                flex-column justify-content-around align-items-center 
                mb-2
                '

        >

            <div

                className='d-flex flex-row justify-content-around align-items-center'
            >
                <Button
                    className='me-2'
                    variant={(offer_code === 'ofertas-del-dia') ? "success" : "outline-success"}
                    size='lg'
                    onClick={() => handleButtonClick('ofertas-del-dia')}
                >
                    Ofertas del Día
                </Button>
                <Button
                    className='me-2'
                    variant={(offer_code === 'gaming') ? "success" : "outline-success"}
                    size='lg'
                    onClick={() => handleButtonClick('gaming')}
                >
                    Gaming
                </Button>


            </div>
        </div>
    </>
}
export const LandingPage = ({
    token,
    handleSearch,
    p_offer_code
}) => {
    const { t } = useTranslation();
    const [cookies] = useCookies(['alani360']);

    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <h4>🤑 {t('Ofertas')}</h4>

            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>
                    {cookies.alani360 === 'MX' &&
                        <p>
                            Compra hasta en 12 cuotas sin interés
                            <br />
                            con tu tarjeta.
                        </p>
                    }
                    <p>
                        <Trans i18nKey="ofertas.mensaje" components={{ br: <br /> }} />
                    </p>

                </center>

            </div>


            <OfertasCategories
                handleSearch={handleSearch}
            />



        </Container >
    </>
}


function Ofertas() {
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const navigate = useNavigate();

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const { t } = useTranslation(); //eslint-disable-line no-unused-vars
    let token = useParams().token;
    let offer_code_param = useParams().offer_code;

    const [showModal, setShowModal] = useState(false);

    const [offer_code, setOfferCode] = useState(offer_code_param);
    const [ofertas, setOfertas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);

    const [showExcludedProducts, setShowExcludedProducts] = useState(0);

    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');

    const [changingPage, setChangingPage] = useState(false);

    const {
        account,
    } = dataReducer;



    const handleSearch = ({
        loading_searching,
        p_offer_code,
        change_page = false,
        clear_search = false
    }) => {
        if (change_page) {
            setChangingPage(true);
        }
        if (clear_search) {
            setSearchTerm('');
        }
        setOfferCode(p_offer_code || offer_code);

        if (loading_searching === "loading") {
            setLoading(true);
        }
        if (loading_searching === "searching") {
            setSearching(true);
        }

        api.ofertas.fetch({
            token: token || "",
            offer_code: p_offer_code || offer_code || "",
            search: clear_search ? '' : searchTerm,
            country_code: cookies.alani360
        }).then(data => {
            setShowExcludedProducts(data.exclude_products ? 0 : 1);
            if (!cookies.alani360) {
                setCookie('alani360', data.country_code);
            }
            // Filter out products where restricted is not 0 or onlineAvailability is false
            if (
                data.products
                &&
                data.exclude_products === 1

            ) {
                data.products = data.products.filter(product =>
                    product.excluded === 0
                );
            }
            setOfertas(data);
            setLoading(false);
            setSearching(false);
            setChangingPage(false);
        }).catch(error => {
            console.error("Error fetching ofertas:", error);
            setLoading(false);
            setSearching(false);
            setChangingPage(false);
        });
    }

    useEffect(() => {
        if (offer_code === 'apple') {
            setLoading(false);
            return;
        }

        handleSearch({
            loading_searching: "loading",
            p_offer_code: offer_code
        });
    }, [location, token, cookies.alani360, setCookie]); // eslint-disable-line react-hooks/exhaustive-deps



    React.useEffect(() => {
        if (account?.account_id) {

            dispatch({
                type: 'ADDRESS_UPDATE',
                data: account
            });
        }

    }, [account]); // eslint-disable-line react-hooks/exhaustive-deps


    if (!offer_code) {
        return <>
            <LandingPage
                token={token}
                handleSearch={handleSearch}
                p_offer_code={offer_code}
            />
        </>
    }

    if (offer_code === 'apple') {
        return <AppleLandingPage
            token={token}
            handleSearch={handleSearch}
        />;
    }

    if (offer_code === 'gaming') {
        return <GamingLandingPage
            token={token}
            handleSearch={handleSearch}
        />;
    }



    if (

        !ofertas?.token_is_valid
        &&
        !cookies.alani360

    ) {
        return <>
            <Header />
            <Container className="p-3 pb-1" style={{ maxWidth: 550 }}>
                <ExpiredToken
                    redirect={`/home//ofertas/[TOKEN]/${offer_code || ''}`}
                />
            </Container>
        </>
    }

    return <>
        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas</title>
            </Helmet>
        </HelmetProvider>

        <Modal show={showModal} onHide={() => { setShowModal(false) }}
            size="sm"
        >


            <Modal.Body>
                <Button
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        border: 'none',
                        backgroundColor: 'transparent',
                        color: 'gray',
                        fontSize: 30
                    }}
                    variant="transparent"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    <IoClose />
                </Button>
                <ExpiredToken
                    backgroundColor="#fff"
                    redirect={`/home//ofertas/[TOKEN]/${offer_code || ''}`}

                />
            </Modal.Body>
        </Modal>




        {
            loading ?

                <div
                    className='d-flex flex-column justify-content-center align-items-center mt-5'
                >
                    <div className="d-flex justify-content-center my-5">

                        <div className="spinner-border text-primary" role="status"></div>
                    </div>

                </div>
                :


                <>
                    <Container className="p-3 p-b0" style={{ maxWidth: 550, textAlign: "center" }}

                    >

                        {
                            !!ofertas?.title

                            &&
                            <>
                                <div

                                    className='d-flex flex-row justify-content-between align-items-start mb-3'
                                >
                                    <div
                                        style={{
                                            width: 100
                                        }}
                                        className='d-flex flex-column justify-content-start align-items-start'
                                    >
                                        <Button

                                            className='m-0 p-0'
                                            variant='transparent'
                                            size='sm'
                                            onClick={() => navigate(!!token ? `/${token}/ofertas/` : '/ofertas/')}
                                        >
                                            <IoChevronBack />&nbsp;{t('Ofertas')}
                                        </Button>
                                    </div>
                                    <div

                                        className='d-flex flex-column justify-content-center align-items-center'
                                    >
                                        <h4
                                            className='p-0  m-0'
                                        >{ofertas.title || offer_code}</h4>

                                    </div>
                                    <div style={{
                                        width: 100
                                    }}
                                        className='d-flex flex-row justify-content-end align-items-center'
                                    >

                                        <Button

                                            className='m-0 p-0'
                                            variant='transparent'
                                            size='sm'
                                            onClick={() => navigate(!!token ? `/${token}/buscar/?b=${window.location.pathname}` : '/buscar/?b=' + window.location.pathname)}
                                        >
                                            {t('Buscar')}&nbsp;<IoSearch />
                                        </Button>

                                    </div>
                                </div>
                                {
                                    ofertas.description !== null ?

                                        <p
                                            style={{
                                                fontSize: 13,
                                                textAlign: 'center',
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            }}
                                            dangerouslySetInnerHTML={{ __html: ofertas.description.replaceAll('-n', '<br />') }}
                                        >
                                        </p>
                                        :
                                        <></>
                                }
                            </>
                        }



                        {
                            ofertas?.oferta_category === 'ofertas-del-dia' &&
                            <p

                                className='d-flex flex-column justify-content-center align-items-center'
                            >

                                <Button

                                    onClick={() => navigate(!!token ? `/${token}/ofertas/` : '/ofertas/')}
                                >
                                    {t('Más Ofertas')}
                                </Button>


                                <center>
                                    {t('ofertas.expirar_cuando_marca_cero')}
                                    <br />
                                    {t('ofertas.escribe_ofertas_del_dia_en_whatsapp')}
                                    <br />
                                    {t('ofertas.todos_los_dias_temos_nuevas_ofertas')}
                                </center>



                                <CountdownTimer />
                            </p>
                        }


                        {
                            ofertas?.title === 'Ofertas Apple' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>
                                <AppleCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    disabled={loading || changingPage}
                                    handleSearch={handleSearch}
                                />
                            </div>
                        }



                        {
                            ofertas?.title === 'ALANI Gaming Ofertas' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>
                                <GamingCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />

                            </div>
                        }


                        {
                            ofertas?.title === 'Ofertas Gaming' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>
                                <GamingCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                                {!changingPage &&
                                    <GamesSubcategoryButtons
                                        offer_code={offer_code}
                                        token={token}
                                        handleSearch={handleSearch}
                                        disabled={loading || changingPage}
                                    />
                                }
                            </div>
                        }
                        {
                            ofertas?.title === 'PC Gaming' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>

                                <GamingCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                                {!changingPage &&
                                    <PCSubcategoryButtons
                                        offer_code={offer_code}
                                        token={token}
                                        handleSearch={handleSearch}
                                        disabled={loading || changingPage}
                                    />
                                }
                            </div>
                        }


                        {
                            ofertas?.title === 'Más Gaming' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>
                                <GamingCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                                {!changingPage &&
                                    <MoreSubcategoryButtons
                                        offer_code={offer_code}
                                        token={token}
                                        handleSearch={handleSearch}
                                        disabled={loading || changingPage}
                                    />
                                }
                            </div>
                        }

                        {ofertas?.oferta_category === 'headphones' &&
                            <div style={{ maxWidth: 370, margin: '0 auto' }}>
                                <HeadPhonesCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                            </div>
                        }

                        {ofertas?.oferta_category === 'laptops' &&
                            <div
                                style={{
                                    maxWidth: 370,
                                    margin: '0 auto'
                                }}
                            >
                                <LaptopCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                            </div>
                        }

                        {ofertas?.oferta_category === 'speakers' &&
                            <div
                                style={{
                                    maxWidth: 370,
                                    margin: '0 auto'
                                }}
                            >
                                <SpeakerCategoriesButtons
                                    offer_code={offer_code}
                                    token={token}
                                    handleSearch={handleSearch}
                                    disabled={loading || changingPage}
                                />
                            </div>
                        }

                        {changingPage &&
                            <div className="d-flex justify-content-center my-5">
                                <div className="spinner-border text-primary" role="status"></div>
                            </div>
                        }

                        {!!ofertas?.pages?.search_allowed && !changingPage &&
                            <Container className="p-1" style={{ maxWidth: 350 }}>

                                <Form.Group className="d-flex flex-row justify-content-between align-items-center p-0 m-0">
                                    <div className="position-relative" style={{ flex: 1 }}>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Filtrar')}
                                            disabled={loading || changingPage}
                                            style={{
                                                backgroundColor: searchTerm ? 'lightyellow' : '#fff',
                                                border: searchTerm ? '2px solid #007bff' : '1px solid #ced4da'
                                            }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleSearch({
                                                        loading_searching: "searching",
                                                    });
                                                }
                                            }}
                                        />
                                        {searchTerm && (
                                            <Button
                                                variant="link"
                                                className="position-absolute"
                                                style={{
                                                    right: 10,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    padding: 0,
                                                    color: '#6c757d'
                                                }}
                                                onClick={() => {
                                                    handleSearch({ loading_searching: "searching", clear_search: true })
                                                }}
                                            >
                                                <IoClose />
                                            </Button>
                                        )}
                                    </div>
                                    <Button
                                        variant={searching ? "secondary" : "dark"}
                                        className='ms-2'
                                        disabled={searching}
                                        onClick={() => handleSearch({ loading_searching: "searching" })}
                                        style={{
                                            width: 120
                                        }}
                                    >
                                        {t('Filtrar')}
                                        {searching && <div className="spinner-border spinner-border-sm text-light ms-2" role="status" style={{ width: '1rem', height: '1rem' }}></div>}
                                    </Button>
                                </Form.Group>

                                {!searching &&
                                    ofertas?.pages?.total > 0 &&
                                    <div className="mt-2 text-center">
                                        <p>{ofertas?.pages?.total} {t('productos')}</p>
                                    </div>
                                }


                            </Container>
                        }


                        {!loading && !searching && (

                            !ofertas?.products?.length ?
                                <div
                                    className="alert alert-warning"
                                    style={{
                                        marginTop: 15,
                                        textAlign: 'center'
                                    }}
                                >

                                    {
                                        searchTerm ?
                                            <b>{t('No se encontraron productos para')} "{searchTerm}"</b>
                                            :
                                            <b>{t('No hay productos en esta oferta')}</b>
                                    }
                                </div>
                                :
                                <OfertasList
                                    products={ofertas?.products}
                                    showExcludedProducts={showExcludedProducts}
                                    tokenIsValid={ofertas.token_is_valid}
                                    onShowModal={() => setShowModal(true)}
                                    token={token}
                                />
                        )}






                        {
                            !loading &&
                            <>
                                {
                                    ofertas.token_is_valid === false &&

                                    <Container className="p-3 p-b0" style={{ maxWidth: 550, }}>

                                        <ExpiredToken
                                            redirect={`/home//ofertas/[TOKEN]/${offer_code || ''}`}

                                        />
                                    </Container>
                                }


                            </>
                        }



                    </Container >




                    {
                        !searching &&
                        !loading &&
                        <Footer />
                    }
                </>
        }







    </>


}

export default Ofertas;
