import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Row,
    Button,
    Modal,
    ToastContainer,
    Toast,
    ButtonGroup
} from 'react-bootstrap';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from "./PaymentUtils";
import {
    useNavigate,
    useParams,
} from "react-router-dom";

import utils from '@Components/utils';
import api from '@Services/api';

import AddressForm from '@Components/AccountAddress';
import { useTranslation } from 'react-i18next';

const PaymentForm = ({
    submitPayment,
    price,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = useParams().token;
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        fetching,
        api_result,
        account,
        billing_address,
        cart
    } = dataReducer;

    const [showToast, setShowToast] = useState(false);
    const resetToast = () => setShowToast(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [installments, setInstallments] = useState(1); //eslint-disable-line

    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: (account.first_name || "") + " " + (account.last_name || ""),
        focus: '',
        issuer: null,
        amount_total: price,
    });

    const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
    const [priceChangeData, setPriceChangeData] = useState(null);

    const [checkingPrice, setCheckingPrice] = useState(false);


    useEffect(() => {
        //call api.price_check 
        setCheckingPrice(true);
        api.price_check({
            token: token
        }).then(data => {
            if (
                !data.items_details[0].product_availability.is_available
                ||
                (data.shopping_cart[0].quantity > data.items_details[0].product_availability.max_quantity)
                ||
                (
                    data.items_details[0].price_comparison.price_changed
                    &&
                    (Math.abs(data.items_details[0].price_comparison.price_difference_usd) > 5)
                )
            ) {
                //show a toast message 
                setPriceChangeData(data);
                setShowPriceChangeModal(true);
                //alert('El producto no está disponible, por favor actualiza el pago');
            }
            setCheckingPrice(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    //showtoast if api_result is not null
    React.useEffect(() => {

        if (api_result?.status === 'OK') {
            navigate(`/${token}/orders/${cart?.shopping_cart_id}?status=success`);
        } else if (api_result !== null) {
            setShowToast(true);
        }
    }, [api_result]); // eslint-disable-line react-hooks/exhaustive-deps

    //showtoast if api_result is not null
    React.useEffect(() => {
        if (fetching === 'cart_paid') {
            setShow(false);
            dispatch({
                type: "FETCHING",
                fetching: null
            });
        }
    }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps



    const [sameAsShippingAddress, setSameAsShippingAddress] = useState(true); //eslint-disable-line
    const [errors, setErrors] = useState({});

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }


    const handleInputChange = (evt) => {
        let { name, value } = evt.target;
        if (name === "number") {
            if (value.length > 19) {
                return;
            }
            let value_issuer = formatCreditCardNumber(value);
            value = value_issuer.value;


            setState((prev) => ({ ...prev, issuer: value_issuer.issuer }));
        } else if (name === "expiry") {
            value = formatExpirationDate(value);
        } else if (name === "cvc") {
            value = formatCVC(value);
        }


        setState((prev) => ({ ...prev, [name]: value }));
    }


    const parse_result_messages = (api_result) => {

        let msg = api_result?.error_message || api_result?.message || api_result?.error || api_result?.status || "Error desconocido";
        msg += ":<br/>"
        if (api_result?.transaction_status_description) {
            msg += "<div>" + api_result?.transaction_status_description + "</div>";
        }

        if (api_result?.errors) {
            if (api_result?.errors?.cc_number) {
                msg += "<div>" + t("Número de tarjeta inválido") + "</div>";
            }
            if (api_result?.errors?.cvc) {
                msg += "<div>" + t("CVC inválido") + "</div>";
            }
            if (api_result?.errors?.expiry) {
                msg += "<div>" + t("Fecha de expiración inválida") + "</div>";
            }
        }

        return msg;
    }


    const handleSubmit = (e) => {


        let _errors = {};

        if (state.number.length < 16) {
            _errors.errors = true;
            _errors.cc_number = "Invalid credit card number";
        }
        if ((state.name || "") === "") {
            _errors.errors = true;
            _errors.name = "Required";
        }
        if (state.expiry.length < 5) {
            _errors.errors = true;
            _errors.expiry = "Invalid expiration date";
        }
        if (state.cvc.length < 3) {
            _errors.errors = true;
            _errors.cvc = "Invalid cvc";
        }


        if (!sameAsShippingAddress) {
            //validate billing address 
            if ((billing_address?.address || "") === "") {
                _errors.errors = true;
                _errors.address = "Required";
            }
            if ((billing_address?.address2 || "") === "") {
                _errors.errors = true;
                _errors.address2 = "Required";
            }
            if ((billing_address?.city || "") === "") {
                _errors.errors = true;
                _errors.city = "Required";
            }
            if ((billing_address?.state || "") === "") {
                _errors.errors = true;
                _errors.state = "Required";
            }
            if ((billing_address?.zipcode || "") === "") {
                _errors.errors = true;
                _errors.zip = "Required";
            }
        }


        setErrors(_errors);

        if (
            _errors.errors
        ) {
            dispatch({
                type: 'API_RESULT_SET',
                result: {
                    error_message: t("Por favor corrija los errores"),
                    errors: _errors
                }
            });
            setShowToast(true);
            return;
        } else {
            /*
    
        "amount_total"   : 1000
        "creditcard": {
            "card_number": "4111111111111111"
            ,"card_name": "Juan Garcia"
            ,"card_due_date": "12/2021"
            ,"card_cvv": "123"
        }   
            */
            setValidated(true);
            handleShow();
        }

    }


    return (<>
        <ToastContainer
            style={{ zIndex: 10000, marginTop: -20 }}
            position='middle-center'
            onClick={() => {
                resetToast();
                dispatch({
                    type: 'API_RESULT_SET',
                    result: null
                });
            }}
        >
            <Toast show={showToast && !!api_result}
                style={{
                    maxWidth: 350,
                }}
                bg='danger'
                onClose={resetToast} delay={18000} autohide
            >
                <Toast.Body
                    className='text-white text-center'
                >
                    <div
                        dangerouslySetInnerHTML={{ __html: parse_result_messages(api_result) }}
                    >
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>

        <Modal show={showPriceChangeModal} onHide={() => setShowPriceChangeModal(false)} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>{t('Cambios en el Producto')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {priceChangeData && (
                    <>
                        {!priceChangeData.items_details[0].product_availability.is_available && (
                            <p>El producto ya no está disponible.</p>
                        )}
                        {priceChangeData.shopping_cart[0].quantity > priceChangeData.items_details[0].product_availability.max_quantity && (
                            <p>La cantidad solicitada excede la disponibilidad actual.</p>
                        )}
                        {priceChangeData.shopping_cart[0].price !== price && (
                            <center>
                                <p>
                                    El precio ha cambiado de
                                    <br />

                                    {utils.formatCurrency(priceChangeData.shopping_cart[0].all_in_price, cart?.currency)}
                                    <br />
                                    a
                                    <br />
                                    {utils.formatCurrency(price, cart?.currency)}
                                </p>
                            </center>
                        )}
                        <p>{t('Por favor, actualice su carrito antes de continuar con el pago.')}</p>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    setShowPriceChangeModal(false);
                    // Redirect to cart or refresh page
                    navigate(`/${token}/cart?edit=1`);

                }}>
                    {t('Actualizar Carrito')}
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>

            <Modal.Header closeButton>
                <Modal.Title>Confirmar Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p
                    style={{ textAlign: 'center' }}
                >
                    Al confirmar el pago aceptas nuestros
                    <br />
                    <a href={`/${token}/terms`}
                        target='_blank'
                        rel='noreferrer'
                    >términos y condiciones</a>
                </p>
                <div
                    className='d-flex justify-content-center align-items-center'
                >
                    <img src='/images/alani360_1.png' style={{ width: 200 }} alt='ALANI360' />
                </div>
                <div className='d-flex justify-content-center align-items-center'
                    style={{ textAlign: 'center' }}
                >
                    {account?.first_name} {account?.last_name}
                    <br />
                    {(state?.issuer || "").toUpperCase()}<br />{state?.number}
                    <br />
                    <b>{utils.formatCurrency(cart?.cart_total, cart?.currency) || ""}</b>
                </div>

            </Modal.Body>
            <Modal.Footer>



                <Button
                    variant="success"
                    disabled={fetching === 'cart_paying'}
                    onClick={() => {


                        let card_due_date = state.expiry.replace('/', '/20');

                        let payment = {
                            "shopping_cart_id": cart?.shopping_cart_id,
                            "account_id": account?.account_id,

                            "name": (account.first_name || "") + " " + (account.last_name || ""),
                            "email": account.email,
                            "phone_number": account.phone,

                            sameAsShippingAddress,
                            "address": null,
                            "street_number": null,
                            "city": null,
                            "state": null,
                            "zipcode": "48822",
                            "country": "mx",
                            "payment_type_code": state.issuer,

                            "currency_code": "MXN",
                            "amount_total": price,

                            "creditcard": {
                                "card_number": state.number.replaceAll(' ', ''),
                                "card_name": state.name,
                                "card_due_date": card_due_date,
                                "card_cvv": state.cvc
                            }
                        }

                        if (sameAsShippingAddress) {
                            payment = {
                                ...payment,
                                "address": account.address,
                                "street_number": account.address2,
                                "city": account.city,
                                "state": account.state,
                                "zipcode": account.zip,
                                "country": account.country,
                            }
                        } else {
                            payment = {
                                ...payment,
                                "address": billing_address.address,
                                "street_number": billing_address.address2,
                                "city": billing_address.city,
                                "state": billing_address.state,
                                "zipcode": billing_address.zipcode,
                                "country": account.country,
                            }
                        }

                        //here
                        payment.instalments = installments;
                        payment.installments = installments;





                        submitPayment(payment);
                    }}>
                    {
                        fetching === 'cart_paying' ?
                            <>


                                <div
                                    className="ms-2 spinner-border spinner-border-sm ml-2"
                                    role="status"
                                    aria-hidden="true"
                                ></div>
                            </>
                            :
                            "Confirmar"
                    }

                </Button>
            </Modal.Footer>
        </Modal>
        <div
            noValidate
            validated={validated}
        >

            <Row
                className='mb-2'
            >

                <div>
                    <Form.Group
                        controlId="cc-number"
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Form.Control
                            type="tel"
                            name="number"
                            placeholder="Número de Tarjeta"
                            value={state.number}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            required
                            isInvalid={errors.cc_number}

                        />

                        {state.issuer &&
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 5,
                                    top: 5
                                }}
                            >
                                <img
                                    src={`/images/${state.issuer}.jpg`}
                                    alt={state.issuer}
                                    style={{
                                        width: 40
                                    }}
                                />
                            </div>
                        }
                    </Form.Group>
                </div>
            </Row>



            <Form.Group
                controlId="cc-number"

            >
                <Form.Control
                    type="text"
                    name="name"
                    placeholder="Nombre"
                    value={state.name}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    required
                    isInvalid={errors.name}

                />

            </Form.Group>





            <div
                className="mt-2 mb-2 row"
            >
                <div className="col-6">
                    <Form.Control
                        type="tel"
                        name="expiry"
                        placeholder="MM/YY"
                        value={state.expiry}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        required
                        isInvalid={errors.expiry}

                    />
                </div>
                <div className="col-6">
                    <Form.Control
                        type="tel"
                        name="cvc"
                        placeholder="CVC"
                        value={state.cvc}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        required
                        isInvalid={errors.cvc}

                    />

                </div>
            </div>



            <div
                className='mb-2 mt-2'
            >
                {account.country !== 'CO' &&
                    account.country !== 'CR' &&
                    <>
                        Pagos < ButtonGroup
                            aria-label="Units"
                            size='sm'

                        >
                            <Button variant="primary" style={{ width: 60 }} onClick={() => installments > 1 ? setInstallments(installments - 1) : 1}>-</Button>
                            <Button variant="light" style={{ width: 60 }}>{installments}</Button>
                            <Button variant="primary" style={{ width: 60 }} onClick={() => installments < 12 ? setInstallments(installments + 1) : 12}>+</Button>
                        </ButtonGroup>
                    </>
                }
            </div>


            <Form.Check // prettier-ignore
                type={'checkbox'}
                id={`check-billing-address`}
                label={t('Igual que dirección de envío')}
                onChange={() => {
                    setSameAsShippingAddress(!sameAsShippingAddress);
                }}
                checked={sameAsShippingAddress}
                disabled={fetching === 'cart_paying'}
            />
            {!sameAsShippingAddress &&
                <div
                    className='
                        mt-3
                        d-flex
                        justify-content-center
                        align-items-center
                        flex-column
                    '
                    style={{
                        border: '1px solid #c0c0c0',
                        padding: 10,
                        borderRadius: 5
                    }}
                >
                    Dirección de Facturación
                    <AddressForm
                        hideLabels={true}
                        isBilling={true}
                        submitText={null}
                        submit={null}
                        errors_override={errors}
                    />
                </div>
            }

            <div
                className='mt-3
                d-flex
                    flex-row
                    justify-content-between
                    align-items-center
                '
            >
                <h3>
                    {utils.formatCurrency(price, cart?.currency) || ""}
                </h3>
                <Button
                    size="lg"
                    style={{
                        minWidth: 120
                    }}
                    variant="success"
                    onClick={() => {


                        handleSubmit();
                    }}
                    disabled={fetching === 'cart_paying' || checkingPrice}
                >

                    {t('Pagar')}
                    {checkingPrice &&
                        <div
                            className="ms-2 spinner-border spinner-border-sm ml-2"
                            role="status"
                            aria-hidden="true"
                        ></div>
                    }
                </Button>

            </div>
        </div >
    </>

    );
}

export default PaymentForm;