import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import FeaturedProduct from '@Components/FeaturedProduct';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const LAPTOP_CATEGORIES = [
    {
        id: 'laptop-windows',
        label: 'Windows',
        subcategories: []
    },
    {
        id: 'laptop-surface',
        label: 'Surface',
        subcategories: []
    },
    {
        id: 'laptop-chromebook',
        label: 'Chromebook',
        subcategories: []
    },
    {
        id: 'laptop-2in1',
        label: '2 in 1',
        subcategories: []
    },
    {
        id: 'laptop-touchscreen',
        label: 'Touchscreen',
        subcategories: []
    },
    {
        id: 'laptop-gaming',
        label: 'Gaming',
        subcategories: []
    },
    {
        id: 'laptop-business',
        label: 'Business',
        subcategories: []
    }
];

export const LaptopLandingPage = ({
    token,
    handleSearch
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return <>
        {!!token ? <HeaderAccount /> : <Header />}
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas Laptops</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 pb-1 d-flex flex-column justify-content-center align-items-center"
            style={{ maxWidth: 350 }}>
            <Button
                variant='outline-dark'
                className='mb-3'
                size='sm'
                onClick={() => navigate(!!token ? `/${token}/ofertas/` : '/ofertas/')}
            >
                <IoChevronBack /> Ofertas
            </Button>

            <h4>💻 {t('Ofertas Laptops')}</h4>

            <div className='d-flex flex-column justify-content-center align-items-center'>
                <center>
                    <p>¡Ofertas de Laptops!</p>
                </center>
            </div>

            <LaptopCategoriesButtons
                offer_code={""}
                token={token}
                handleSearch={handleSearch}
            />
            <FeaturedProduct
                name="<h5>MacBook Air M2</h5>"
                description="Además de estos productos en oferta o con descuento, tenemos miles de productos más y exclusivos de <b>Best&nbsp;Buy powered by ALANI 360</b>:"
                imageUrl="https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6509/6509650_sd.jpg"
                imageStyle={{
                    width: 320,
                }}
                url={!!token ? `/${token}/sku/6509650` : `/_/sku/6509650`}
            />
        </Container>
    </>
}

export const LaptopCategoriesButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const handleClick = (path, searchParams) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${path}`);
        handleSearch({
            change_page: true,
            loading_searching: searchParams.loading_searching,
            p_offer_code: searchParams.p_offer_code
        });
    };

    const isActiveCategory = (categoryId) => {
        const category = LAPTOP_CATEGORIES.find(cat => cat.id === categoryId);
        return category && (
            offer_code === category.id ||
            category.subcategories.some(sub => sub.id === offer_code)
        );
    };

    return <div className='d-flex flex-wrap justify-content-center align-items-center mb-2'>
        {LAPTOP_CATEGORIES.map(category => (
            <Button
                key={category.id}
                className='me-2 mb-2'
                variant={isActiveCategory(category.id) ? "success" : "outline-success"}
                size='sm'
                onClick={() => handleClick(category.id, {
                    loading_searching: "searching",
                    p_offer_code: category.id
                })}
                disabled={disabled}
            >
                {category.label}
            </Button>
        ))}
    </div>
} 