import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Button from 'react-bootstrap/Button';

const CountryButton = ({ setCookie, navigate, country, flag, name, language }) => {
    const { i18n } = useTranslation();

    return (
        <Button
            variant="transparent"
            style={{
                fontSize: 12
            }}
            className="d-flex flex-column justify-content-center align-items-center m-2"
            onClick={() => {
                i18n.changeLanguage(language);
                setCookie('alani360', country, { path: '/' });
                window.location.reload(); // Add this line to refresh the page

            }}
        >
            <img
                style={{ width: 40 }}
                alt={name}
                src={flag}
            />
            {name}
        </Button>
    );
};

const CountryModal = ({ show, onHide }) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    return (
        <Modal show={show} onHide={onHide} centered>

            <Modal.Body>
                <div className="d-flex flex-wrap justify-content-center">
                    {process.env.REACT_APP_COUNTRY_CL === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CL"
                            flag="/images/flags/CL.png"
                            name="Chile"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_CO === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CO"
                            flag="/images/flags/CO.png"
                            name="Colombia"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_CR === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CR"
                            flag="/images/flags/CR.png"
                            name="Costa Rica"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_DO === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="DO"
                            flag="/images/flags/DO.png"
                            name="Rep. Dominicana"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_MX === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="MX"
                            flag="/images/flags/MX.png"
                            name="México"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_PE === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="PE"
                            flag="/images/flags/PE.png"
                            name="Perú"
                            language="es"
                        />
                    }
                    {process.env.REACT_APP_COUNTRY_BR === "1" &&
                        <CountryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="BR"
                            flag="/images/flags/BR.png"
                            name="Brazil"
                            language="pt"
                        />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CountryModal;
